var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row justify-content-end mt-n3"},[(_vm.purpose == 'master')?_c('div',{staticClass:"col"},[(_vm.manipulateBtn == true)?_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$bvModal.show('modal-hidden-doctor')}}},[_vm._v("Dokter yang disembunyikan")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"col-md-3 col-12"},[_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',{attrs:{"squared":""}},[_c('b-icon-search')],1)]},proxy:true}])},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Cari Nama Dokter"},on:{"keyup":_vm.filterByName},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}})],1)],1)]),_c('b-table',{staticClass:"mt-3",attrs:{"striped":"","hover":"","responsive":"","items":_vm.items,"fields":_vm.fields},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({
          width:
            field.key === 'actions' ? (_vm.purpose == 'master' ? '30%' : '') : '',
        })})})}},{key:"cell(name)",fn:function(data){return [_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(data.item.name)+" "),(data.item.is_satusehat_skip == 0)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fas fa-stethoscope text-primary",attrs:{"title":"Dokter"}}):_vm._e()])]}},{key:"cell(email_mobile_phone)",fn:function(data){return [_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(data.item.email))]),_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(data.item.mobile_phone))])]}},{key:"cell(photo)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center align-items-center image-container"},[_c('div',{staticStyle:{"height":"50px","width":"50px","position":"relative"}},[_c('img',{staticClass:"image",attrs:{"src":data.item.photo}})])])]}},{key:"cell(actions)",fn:function(data){return [(_vm.purpose == 'master')?[_c('div',{staticClass:"d-flex"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-info",attrs:{"size":"sm","title":"Detail"},on:{"click":function($event){return _vm.$router.push({ path: '/doctor/detail/' + data.item.id })}}},[_c('i',{staticClass:"fas fa-eye px-0"})]),(_vm.manipulateBtn == true)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-success",attrs:{"size":"sm","title":"Edit"},on:{"click":function($event){return _vm.$router.push({ path: '/doctor/edit/' + data.item.id })}}},[_c('i',{staticClass:"fas fa-edit px-0"})]):_vm._e(),(_vm.appointmentBtn == true)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-info",attrs:{"size":"sm","title":"Perjanjian"},on:{"click":function($event){return _vm.$router.push({
                path: '/doctor/doctor-appointment/' + data.item.id,
              })}}},[_c('i',{staticClass:"fas fa-tasks px-0"})]):_vm._e(),(_vm.holidayBtn == true)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-warning",attrs:{"size":"sm","title":"Libur"},on:{"click":function($event){return _vm.$router.push({ path: '/doctor/holiday/' + data.item.id })}}},[_c('i',{staticClass:"fas fa-calendar-times px-0"})]):_vm._e(),(_vm.scheduleBtn == true)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-success",attrs:{"size":"sm","title":"Jadwal"},on:{"click":function($event){return _vm.$router.push({ path: '/doctor/schedule/' + data.item.id })}}},[_c('i',{staticClass:"fas fa-calendar-check px-0"})]):_vm._e(),(_vm.manipulateBtn == true)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-warning",attrs:{"size":"sm","title":"Sembunyikan"},on:{"click":function($event){return _vm.btnHideOnClick(data.item.id, 1)}}},[_c('i',{staticClass:"fas fa-eye-slash px-0"})]):_vm._e(),(_vm.manipulateBtn == true)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn-danger",attrs:{"size":"sm","title":"Hapus"},on:{"click":function($event){return _vm.btnDeleteOnClick(data.item.id)}}},[_c('i',{staticClass:"fas fa-trash px-0"})]):_vm._e()],1)]:_vm._e(),(_vm.purpose == 'modal')?[_c('b-button',{staticClass:"mr-1 btn-info",attrs:{"size":"sm"},on:{"click":function($event){return _vm.chooseDoctor({
              id: data.item.id,
              name: ((data.item.name) + " - " + (data.item.polyclinic_name)),
            })}}},[_vm._v(" Pilih ")])]:_vm._e(),(_vm.purpose == 'modal-doctor-multiple')?[_c('b-form-checkbox',{attrs:{"checked":data.item.checked,"id":("checkbox-pick-item-" + (data.item.id)),"name":("checkbox-pick-item-" + (data.item.id))},on:{"change":function($event){return _vm.chooseDoctorMultiple(data.item, $event)}}})]:_vm._e()]}}])}),(_vm.items.length != 0)?_c('b-pagination',{staticClass:"mt-4",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage},on:{"page-click":_vm.pageOnClick},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e(),_c('b-modal',{attrs:{"id":"modal-hidden-doctor","size":"xl","title":"Daftar Dokter yang disembunyikan"}},[_c('b-table',{staticClass:"mt-3",attrs:{"striped":"","hover":"","responsive":"","items":_vm.hiddenItems,"fields":_vm.hiddenFields},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({
            width:
              field.key === 'actions'
                ? _vm.purpose == 'master'
                  ? '5%'
                  : ''
                : '',
          })})})}},{key:"cell(email_mobile_phone)",fn:function(data){return [_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(data.item.email))]),_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(data.item.mobile_phone))])]}},{key:"cell(photo)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center align-items-center image-container"},[_c('div',{staticStyle:{"height":"50px","width":"50px","position":"relative"}},[_c('img',{staticClass:"image",attrs:{"src":data.item.photo}})])])]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"d-flex"},[(_vm.manipulateBtn == true)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-warning",attrs:{"size":"sm","title":"Hapus dari daftar sembunyi"},on:{"click":function($event){return _vm.btnHideOnClick(data.item.id, 0)}}},[_c('i',{staticClass:"fas fa-minus-circle px-0"})]):_vm._e()],1)]}}])}),(_vm.hiddenItems.length != 0)?_c('b-pagination',{staticClass:"mt-4",attrs:{"total-rows":_vm.hiddenTotalRows,"per-page":_vm.hiddenPerPage},on:{"page-click":_vm.hiddenPageOnClick},model:{value:(_vm.hiddenCurrentPage),callback:function ($$v) {_vm.hiddenCurrentPage=$$v},expression:"hiddenCurrentPage"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }