<template>
  <div>
    <!-- Filter -->
    <div
      :class="`row align-items-center justify-content-start ${
        hasPatient ? 'mt-3' : ''
      }`"
    >
      <div :class="hasPatient ? 'col-md-9' : 'col-md-6'">
        <span class="d-none d-sm-block">
          <b-input-group>
            <template #prepend>
              <b-button squared @click="resetFilterPeriode" variant="danger"
                >Reset</b-button
              >
              <b-button squared @click="filterByPeriode" variant="success"
                >Cari</b-button
              >
            </template>
            <b-form-input
              v-model="filter.start_date"
              type="text"
              placeholder="Tanggal Awal"
              autocomplete="off"
              readonly
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="filter.start_date"
                button-only
                right
                reset-button
                locale="id"
                aria-controls="example-input"
              ></b-form-datepicker>
            </b-input-group-append>
            <b-button squared variant="light">s/d</b-button>
            <b-form-input
              v-model="filter.end_date"
              type="text"
              placeholder="Tanggal Akhir"
              autocomplete="off"
              readonly
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="filter.end_date"
                button-only
                right
                reset-button
                locale="id"
                aria-controls="example-input"
              ></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </span>
        <span class="d-block d-sm-none">
          <div class="row">
            <div class="col-12 pb-1">
              <b-input-group>
                <template #prepend>
                  <b-button squared @click="resetFilterPeriode" variant="danger"
                    >Reset</b-button
                  >
                  <b-button squared @click="filterByPeriode" variant="success"
                    >Cari</b-button
                  >
                </template>
                <b-form-input
                  v-model="filter.start_date"
                  type="text"
                  placeholder="Tanggal Awal"
                  autocomplete="off"
                  readonly
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="filter.start_date"
                    button-only
                    right
                    reset-button
                    locale="id"
                    aria-controls="example-input"
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="col-12 pt-0">
              <b-input-group>
                <b-button squared variant="light">s/d</b-button>
                <b-form-input
                  v-model="filter.end_date"
                  type="text"
                  placeholder="Tanggal Akhir"
                  autocomplete="off"
                  readonly
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="filter.end_date"
                    button-only
                    right
                    reset-button
                    locale="id"
                    aria-controls="example-input"
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
        </span>
      </div>
      <div class="col-md-3">
        <treeselect
          v-if="currentUser.user_type_id != 1"
          v-model="filter.doctor_id"
          :multiple="false"
          :options="doctors"
          placeholder="Cari Berdasar Dokter"
          @select="filterByDoctor"
        />
      </div>
      <div class="col-md-3" v-if="!hasPatient">
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            type="text"
            v-model="filter.patient_name"
            placeholder="Cari Nama Pasien"
            @keyup="filterByPatient"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>

    <!-- Table -->
    <b-table
      striped
      class="mt-3"
      hover
      :items="items"
      :fields="fields"
      :style="'white-space: nowrap'"
      responsive
    >
      <template #cell(date)="data">
        {{ data.item.date.split(" ")[0] }}
      </template>
      <template #cell(doctor_name)="data">
        {{ data.item.doctor_name }}
      </template>
      <template #cell(patient_name)="data">
        {{ data.item.patient_name }}
      </template>
      <template #cell(display_medicine)="data">
        {{ displayMedicine(data.item.display_medicine) }}
      </template>
      <template #cell(actions)="data">
        <template v-if="hasPatient">
          <b-button
            size="sm"
            class="mr-1 btn-success"
            v-b-tooltip.hover
            title="Detail"
            v-b-modal.modal-print
            @click="btnDetailOnClick(data.item.id)"
            ><i class="fas fa-eye px-0"></i
          ></b-button>
          <b-button
            size="sm"
            class="btn-info"
            v-b-tooltip.hover
            title="Download"
            @click="btnDownloadOnClick(data.item.id)"
            ><i class="fas fa-download px-0"></i
          ></b-button>
        </template>
        <template v-if="!hasPatient">
          <b-button
            size="sm"
            class="mr-1"
            variant="primary"
            v-b-tooltip.hover
            title="Cetak Stiker Obat"
            @click="btnPrint(data.item)"
            ><i class="fas fa-print px-0"></i
          ></b-button>
          <b-button
            size="sm"
            class="mr-1 btn-info"
            v-b-tooltip.hover
            title="Detail"
            @click="
              $router.push({ path: '/prescriptions/detail/' + data.item.id })
            "
            ><i class="fas fa-eye px-0"></i
          ></b-button>
          <b-button
            size="sm"
            class="mr-1 btn-success"
            v-b-tooltip.hover
            title="Edit"
            v-if="manipulateBtn == true"
            @click="
              $router.push({ path: '/prescriptions/edit/' + data.item.id })
            "
            ><i class="fas fa-edit px-0"></i
          ></b-button>
          <b-button
            size="sm"
            class="btn-danger"
            v-b-tooltip.hover
            title="Hapus"
            v-if="manipulateBtn == true"
            @click="btnDeleteOnClick(data.item.id)"
            ><i class="fas fa-trash px-0"></i
          ></b-button>
        </template>
      </template>
    </b-table>
    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"
    ></b-pagination>

    <StickerPrint :dataprint="printdata" />
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import Treeselect from "@riophae/vue-treeselect";
import { debounce } from "@/core/modules/Helper.js";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { getUser } from "@/core/services/jwt.service.js";
import StickerPrint from "@/component/prescriptions/StickerPrint.vue";

export default {
  props: {
    items: Array,
    fields: Array,
    hasPatient: Boolean,
    perPage: Number,
    currentPage: Number,
    totalRows: Number,
  },

  components: {
    Card,
    Treeselect,
    StickerPrint,
  },

  data() {
    return {
      // Filter
      filter: {
        doctor_id: "",
        patient_name: "",
        start_date: "",
        end_date: "",
      },
      // Options
      doctors: [],
      // access management
      manipulateBtn: false,
      // current user
      currentUser: getUser(),
      printdata: {},
    };
  },

  methods: {
    btnPrint(item) {
      this.printdata = item;

      setTimeout(() => {
        this.$htmlToPaper("printSticker", {
          name: "cetak stiker",
          specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
          styles: [
            `/presc-sticker-new.css`,
            "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
            "https://unpkg.com/kidlat-css/css/kidlat.css",
          ],
        });
      }, 1000);
    },

    pageOnClick(evt, page) {
      evt.preventDefault();
      this.$emit("pageOnClick", page);
    },

    async getDoctorsOption() {
      let response = await module.setDoctorTreeSelect("doctors");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.doctors = response.data;
        this.doctors.unshift({
          label: "Filter Berdasar Dokter",
          id: "",
          isDisabled: true,
        });
      }
    },

    resetFilterPeriode() {
      this.filter = validation.clearForm(this.filter);
      if (this.currentUser.user_type_id == 1) {
        this.filter.doctor_id = this.currentUser.user_id;
      }
      this.$emit("filterByPeriode", this.filter);
    },

    filterByPeriode() {
      this.$emit("filterByPeriode", this.filter);
    },

    filterByPatient() {
      debounce(() => {
        this.$emit("filterByPatient", this.filter);
      }, 500);
    },

    async filterByDoctor(evt) {
      await this.filter.doctor_id;
      this.$emit("filterByDoctor", this.filter);
    },

    btnDetailOnClick(id) {
      this.$emit("btnDetailOnClick", id);
    },

    btnDownloadOnClick(id) {
      this.$emit("btnDownloadOnClick", id);
    },

    btnDeleteOnClick(id) {
      this.$emit("btnDeleteOnClick", id);
    },

    displayMedicine(medicines) {
      let str = "";
      medicines.forEach(function (value, index) {
        str += value.name + " - " + value.dosage;
        if (index != medicines.length - 1) {
          if (medicines.length > 1) str += ", ";
        }
      });
      return str;
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        console.log("looping");

        if (access_right[a] == "8002") {
          this.manipulateBtn = true;
        }
      }
    },
  },

  watch: {
    "filter.doctor_id": function (newVal, oldVal) {
      if (typeof newVal === "undefined") {
        this.filter.doctor_id = "";
        this.filterByDoctor();
      }
    },
  },

  mounted() {
    if (this.currentUser.user_type_id != 1) {
      // Get Options
      this.getDoctorsOption();
    }
    this.setActiveMenu();

    this.printdata = this.items[0];
    console.log("printdata ================", this.printdata);
  },
  watch: {
    items: function (newVal, oldVal) {
      this.printdata = this.items[0];
      console.log("wactch ================ printdata", this.printdata);
    },
  },
};
</script>

<style></style>