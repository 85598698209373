<template>
  <div>
    <!--begin::Chart-->
    <apexchart
      class="card-rounded-bottom"
      :options="chartOptions"
      :series="series"
      type="pie"
      width="100%"
      height="390"
    ></apexchart>
    <!--end::Chart-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import module from "@/core/modules/CrudModule.js";
import moment from "moment";
export default {
  name: "widget-12",
  data() {
    return {
      chartOptions: {},
      // series: [
      //   {
      //     name: "Jumlah :",
      //     data: [],
      //   },
      // ],
      sum: "",
      arr: [],
      chartLoad: false,
      sumLoad: false,
      // filter
      filter: {
        start_date: "",
        end_date: "",
      },

      tooltipPeriod: "thisWeek",

      // chart
      series: [],
      chartOptions: {
        chart: {
          type: "pie",
        },
        labels: [],
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            const percentage = val.toFixed(2); // Format dengan dua desimal
            const value = opts.w.config.series[opts.seriesIndex];
            return `${value} (${percentage}%)`; // Menampilkan nilai asli + persentase
          },
        },
        legend: {
          position: "bottom",
        },
        colors: ["#a12260", "#7922a1"],
      },
    };
  },
  methods: {
    Filter(tool, filter) {
      this.tooltipPeriod = tool;
      this.chartLoad = false;
      console.log("tool Filter", tool, filter);
      if (tool == "period") {
        console.log("dah ke set", filter);
        this.filter.start_date = filter.start_date;
        this.filter.end_date = filter.end_date;
      }

      this.getData();

      mapGetters(["layoutConfig"]);
    },
    generalFilter() {
      this.tooltipPeriod = "custom";
      this.chartLoad = false;
      this.getData();
      mapGetters(["layoutConfig"]);
    },
    periodFilter() {
      this.tooltipPeriod = "custom";
    },
    resetFilter() {
      this.chartLoad = false;
      this.filter.start_date = "";
      this.filter.end_date = "";
      this.getData();
      mapGetters(["layoutConfig"]);
    },

    async getData(tool) {
      window.arrData = [];
      let filterParams = `?start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&filter=${this.tooltipPeriod}`;
      let response = await module.get(
        "graphic-patient/gender",
        `${filterParams}`
      );

      let patient = [];
      let gender = [];

      //  gender
      for (let b = 0; b < response.items.length; b++) {
        if (response.items[b].gender == "male") {
          patient.push(response.items[b].amount);
          gender.push("Laki-laki");
        } else if (response.items[b].gender == "female") {
          patient.push(response.items[b].amount);
          gender.push("Perempuan");
        }
      }

      // this.series = patient.map((patient) => parseFloat(patient.price));
      this.series = patient;
      window.arrData = gender;
      // window.arrData = patient.map((patient) => String(patient.ref_name));
      this.chartLoad = true;

      this.chartOptions = {
        ...this.chartOptions,
        ...{
          labels: window.arrData,
          xaxis: {
            categories: window.arrData,
            title: {
              text: "Persentase Jenis Kelamin",
            },
          },
        },
      };
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
    this.getData();
    if (this.chartLoad == true) {
    }

    this.$root.$on("filterChart", (tool, filter) => {
      console.log("============ receip emit ================", tool, filter);

      this.Filter(tool, filter);
    });
    // reference; kt_stats_widget_12_chart
  },
};
</script>