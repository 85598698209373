<template>
  <v-app>
    <div>
      <!-- Filter -->
      <div
        :class="`row align-items-center ${
          isToday ? 'justify-content-end' : 'justify-content-between'
        } ${!hasPatient ? (!hasDoctor ? 'mt-n3' : '') : 'mt-2'}`"
      >
        <div
          v-if="!isToday"
          :class="
            !hasDoctor
              ? !hasPatient
                ? 'col-md-6 col-12'
                : 'col-md-9 col-12'
              : 'col-md-9 col-12'
          "
        >
          <button
            class="btn mx-1 btn-timeseries"
            style="
              padding-left: 5px;
              padding-right: 5px;
              padding-top: 7px;
              padding-bottom: 7px;
            "
            @click="btnActiveOnClick('days')"
            v-bind:class="{
              'btn-info': btnActive.days,
              'btn-outline-info': btnActive.days == false,
            }"
          >
            Hari Ini
          </button>
          <button
            class="btn mx-1 btn-timeseries"
            style="
              padding-left: 5px;
              padding-right: 5px;
              padding-top: 7px;
              padding-bottom: 7px;
            "
            @click="btnActiveOnClick('month')"
            v-bind:class="{
              'btn-info': btnActive.month,
              'btn-outline-info': btnActive.month == false,
            }"
          >
            Bulan Ini
          </button>
          <button
            class="btn mx-1 btn-timeseries"
            style="
              padding-left: 5px;
              padding-right: 5px;
              padding-top: 7px;
              padding-bottom: 7px;
            "
            @click="btnActiveOnClick('lastMonth')"
            v-bind:class="{
              'btn-info': btnActive.lastMonth,
              'btn-outline-info': btnActive.lastMonth == false,
            }"
          >
            Bulan Kemarin
          </button>
          <button
            class="btn mx-1 btn-timeseries"
            style="
              padding-left: 5px;
              padding-right: 5px;
              padding-top: 7px;
              padding-bottom: 7px;
            "
            @click="btnActiveOnClick('year')"
            v-bind:class="{
              'btn-info': btnActive.year,
              'btn-outline-info': btnActive.year == false,
            }"
          >
            Tahun Ini
          </button>
          <button
            class="btn mx-1 btn-timeseries"
            style="
              padding-left: 5px;
              padding-right: 5px;
              padding-top: 7px;
              padding-bottom: 7px;
            "
            v-bind:class="{
              'btn-info': btnActive.period,
              'btn-outline-info': btnActive.period == false,
            }"
            @click="btnPeriodeOnClick"
          >
            Periode
          </button>
        </div>
        <div
          class="col-md-3 col-6 mt-md-0 mt-n4"
          v-if="!hasDoctor && !isMyAppointment && currentUser.user_type_id != 1"
        >
          <treeselect
            v-model="filter.doctor_id"
            :multiple="false"
            :options="doctors"
            placeholder="Filter Berdasar Dokter"
            @select="filterByDoctor"
          />
        </div>
        <div class="col-md-3 col-6 mt-md-0 mt-n4" v-if="!hasPatient">
          <b-input-group>
            <template #prepend>
              <b-input-group-text squared>
                <b-icon-search></b-icon-search>
              </b-input-group-text>
            </template>
            <b-form-input
              type="text"
              v-model="filter.patient_name"
              placeholder="Cari Nama Pasien"
              @keyup="filterByPatient"
            ></b-form-input>
          </b-input-group>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-md-2">
          <span
            style="width: 150px; color: #a12260 !important"
            class="font-weight-bold"
            >{{ totalRows }} Baris Data</span
          >
        </div>
        <div class="col-12 col-md-6 col-xl-6" v-if="onShowPeriod">
          <span class="d-none d-sm-block">
            <b-input-group>
              <b-form-input
                v-model="filter.start_date"
                type="text"
                placeholder="Tanggal Awal"
                autocomplete="off"
                readonly
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  v-model="filter.start_date"
                  button-only
                  right
                  reset-button
                  locale="id"
                  aria-controls="example-input"
                ></b-form-datepicker>
              </b-input-group-append>
              <b-button squared variant="light">s/d</b-button>
              <b-form-input
                v-model="filter.end_date"
                type="text"
                placeholder="Tanggal Akhir"
                autocomplete="off"
                readonly
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  v-model="filter.end_date"
                  button-only
                  right
                  reset-button
                  locale="id"
                  aria-controls="example-input"
                ></b-form-datepicker>
              </b-input-group-append>
              <template #append>
                <b-button squared @click="filterByPeriode" variant="success"
                  >Cari</b-button
                >
                <b-button squared @click="resetFilterPeriode" variant="danger"
                  >Reset</b-button
                >
              </template>
            </b-input-group>
          </span>
          <span class="d-block d-sm-none">
            <div class="row">
              <div class="col-12 pb-1">
                <b-input-group>
                  <b-form-input
                    v-model="filter.start_date"
                    type="text"
                    placeholder="Tanggal Awal"
                    autocomplete="off"
                    readonly
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="filter.start_date"
                      button-only
                      right
                      reset-button
                      locale="id"
                      aria-controls="example-input"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div class="col-12 pt-0">
                <b-input-group>
                  <b-button squared variant="light">s/d</b-button>
                  <b-form-input
                    v-model="filter.end_date"
                    type="text"
                    placeholder="Tanggal Akhir"
                    autocomplete="off"
                    readonly
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="filter.end_date"
                      button-only
                      right
                      reset-button
                      locale="id"
                      aria-controls="example-input"
                    ></b-form-datepicker>
                  </b-input-group-append>
                  <template #prepend>
                    <b-button squared @click="filterByPeriode" variant="success"
                      >Cari</b-button
                    >
                    <b-button
                      squared
                      @click="resetFilterPeriode"
                      variant="danger"
                      >Reset</b-button
                    >
                  </template>
                </b-input-group>
              </div>
            </div>
          </span>
        </div>
      </div>

      <!-- Nav -->
      <template v-if="tabFiled == 0">
        <b-tabs class="mt-4 appointment-status-tabs" v-model="tabPosition">
          <b-tab title="Semua" active @click="resetAppointmentStatus"></b-tab>
          <div v-for="status in appointmentStatuses" :key="status.id">
            <b-tab
              :title="status.name"
              @click="navStatusOnClick(status.id)"
            ></b-tab>
          </div>
        </b-tabs>
      </template>
      <template v-if="tabFiled == 1">
        <b-tabs class="mt-4 appointment-status-tabs" v-model="tabPosition">
          <b-tab title="Semua" @click="resetAppointmentStatus"></b-tab>
          <b-tab title="Diajukan" active @click="navStatusOnClick(1)"></b-tab>
          <div v-for="status in appointmentStatuses" :key="status.id">
            <b-tab
              :title="status.name"
              v-if="status.id != 1"
              @click="navStatusOnClick(status.id)"
            ></b-tab>
          </div>
        </b-tabs>
      </template>

      <div class="d-flex justify-content-start align-items-center mt-5">
        <button
          class="btn btn-primary mr-1"
          @click="$bvModal.show('modal-excel')"
        >
          Ekspor <i class="fas fa-print px-0 ml-1"></i>
        </button>
      </div>

      <!-- Table -->
      <b-table
        striped
        hover
        responsive
        class="mt-3"
        :items="items"
        :fields="fields"
      >
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{
              width:
                hasDoctor || hasPatient
                  ? field.key === 'actions'
                    ? '15%'
                    : ''
                  : field.key === 'actions'
                  ? '25%'
                  : '',
            }"
          />
        </template>
        <template #cell(doctor_name_action_type_name)="data">
          <span class="d-block">{{ data.item.doctor_name }}</span>
          <span class="d-block">{{ data.item.action_type_name }}</span>
        </template>
        <template #cell(display_date)="data">
          {{
            new Date(data.item.date)
              .toLocaleString("id-ID", {
                weekday: "long",
                day: "numeric",
                month: "2-digit",
                year: "numeric",
              })
              .replaceAll("/", "-")
          }}
        </template>
        <template #cell(appointment_status_name)="data">
          <div class="text-center">
            <button
              :class="`mr-1 btn btn-sm font-weight-bold ${btnStatusColor(
                data.item.appointment_status_id
              )}`"
              @click="btnStatusOnclick(data.item)"
              v-b-modal.modal-status
            >
              {{ data.item.appointment_status_name }}
            </button>
          </div>
        </template>
        <template #cell(actions)="data">
          <div class="d-flex" v-if="currentUser.user_type_id != 1">
            <b-button
              size="sm"
              class="mr-1"
              variant="primary"
              v-b-tooltip.hover
              title="Cetak Stiker Obat"
              @click="showDataPreview(data.item)"
              ><i class="fas fa-print px-0"></i
            ></b-button>
            <b-button
              size="sm"
              class="mr-1 btn-info"
              v-b-tooltip.hover
              title="Detail"
              @click="
                $router.push({ path: '/appointment/detail/' + data.item.id })
              "
              ><i class="fas fa-eye px-0"></i
            ></b-button>
            <template v-if="!hasPatient">
              <template v-if="!isMyAppointment">
                <b-button
                  size="sm"
                  class="mr-1 btn-success"
                  v-b-tooltip.hover
                  title="Edit"
                  v-if="manipulateBtn == true"
                  @click="
                    $router.push({ path: '/appointment/edit/' + data.item.id })
                  "
                  ><i class="fas fa-edit px-0"></i
                ></b-button>
                <b-button
                  size="sm"
                  class="btn-danger mr-1"
                  v-b-tooltip.hover
                  title="Hapus"
                  v-if="manipulateBtn == true"
                  @click="btnDeleteOnClick(data.item.id)"
                  ><i class="fas fa-trash px-0"></i
                ></b-button>
              </template>
            </template>
            <template v-if="data.item.appointment_status_id == 4">
              <b-button
                v-if="caseManagerShortcut"
                size="sm"
                class="mr-1 btn-success"
                v-b-tooltip.hover
                title="Tambah Rekam Medis"
                @click="addMedicalRecord(data.item)"
                ><i class="fas fa-user-md px-0"></i
              ></b-button>
              <b-button
                v-if="paymentShortcut"
                size="sm"
                class="mr-1 btn-primary"
                variant="primary"
                v-b-tooltip.hover
                title="Tambah Invoice"
                @click="
                  $router.push(
                    `/payment/add?doctor_id=${data.item.doctor_id}&patient_id=${data.item.patient_id}&patient_name=${data.item.patient_name}&appointment_id=${data.item.id}`
                  )
                "
                ><i class="fas fa-money-bill-wave px-0"></i
              ></b-button>
              <b-button
                v-if="prescriptionShortcut"
                size="sm"
                class="mr-1 btn-info"
                v-b-tooltip.hover
                title="Tambah Resep"
                @click="
                  $router.push(
                    `/prescriptions/add?doctor_id=${data.item.doctor_id}&doctor_name=${data.item.doctor_name}&patient_id=${data.item.patient_id}&patient_name=${data.item.patient_name}&appointment_id=${data.item.id}`
                  )
                "
                ><i class="fas fa-receipt px-0"></i
              ></b-button>
              <b-button
                v-if="medicalActionShortcut"
                size="sm"
                class="btn-success"
                v-b-tooltip.hover
                title="Tambah Tindakan Medis"
                @click="
                  $router.push(
                    `/medical-actions/add?doctor_id=${data.item.doctor_id}&doctor_name=${data.item.doctor_name}&patient_id=${data.item.patient_id}&patient_name=${data.item.patient_name}&appointment_id=${data.item.id}`
                  )
                "
                ><i class="fas fa-diagnoses px-0"></i
              ></b-button>
            </template>
          </div>
          <template v-if="data.item.appointment_status_id == 4 && currentUser.user_type_id == 1">
            <b-button
              v-if="caseManagerShortcut"
              size="sm"
              class="mr-1 btn-success"
              v-b-tooltip.hover
              title="Tambah Rekam Medis"
              @click="addMedicalRecord(data.item)"
              ><i class="fas fa-user-md px-0"></i
            ></b-button>
            <!-- <b-button
              v-if="prescriptionShortcut"
              size="sm"
              class="mr-1 btn-info"
              v-b-tooltip.hover
              title="Tambah Resep"
              @click="
                $router.push(
                  `/prescriptions/add?doctor_id=${data.item.doctor_id}&doctor_name=${data.item.doctor_name}&patient_id=${data.item.patient_id}&patient_name=${data.item.patient_name}&appointment_id=${data.item.id}`
                )
              "
              ><i class="fas fa-receipt px-0"></i
            ></b-button>
            <b-button
              v-if="medicalActionShortcut"
              size="sm"
              class="btn-success"
              v-b-tooltip.hover
              title="Tambah Tindakan Medis"
              @click="
                $router.push(
                  `/medical-actions/add?doctor_id=${data.item.doctor_id}&doctor_name=${data.item.doctor_name}&patient_id=${data.item.patient_id}&patient_name=${data.item.patient_name}&appointment_id=${data.item.id}`
                )
              "
              ><i class="fas fa-diagnoses px-0"></i
            ></b-button> -->
          </template>
        </template>
      </b-table>
      <b-pagination
        v-if="items.length != 0"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        @page-click="pageOnClick"
        class="mt-4"
      ></b-pagination>

      <!-- Modal Status -->
      <b-modal
        id="modal-status"
        title="Ubah Status Perjanjian"
        size="lg"
        @ok="modalStatusOnSubmit"
        @hidden="modalStatusOnHide"
      >
        <b-form-group
          label="Pilih Status Perjanjian"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-radio-group
            id="radio-group-status"
            v-model="item.appointment_status_id"
            :options="radioAppointmentStatus"
            :aria-describedby="ariaDescribedby"
            name="appointment-status-id"
          ></b-form-radio-group>
        </b-form-group>
      </b-modal>

      <!-- modal export -->
      <b-modal
        id="modal-excel"
        size="xl"
        title="Expor Daftar Perjanjian"
        style="max-width: 30px; overflow: scroll"
        hide-footer
      >
        <div class="d-flex justify-content-start align-items-center mt-5">
          <button
            class="btn btn-warning mr-1"
            v-b-tooltip.hover
            title="Download Excel"
            @click="btnExcelOnClick"
          >
            <i class="fas fa-file-excel px-0"></i>
          </button>
        </div>
        <b-table
          striped
          hover
          responsive
          class="mt-3"
          :items="itemPopUp"
          :fields="fieldPopUp"
        >
          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{
                width:
                  hasDoctor || hasPatient
                    ? field.key === 'actions'
                      ? '15%'
                      : ''
                    : field.key === 'actions'
                    ? '25%'
                    : '',
              }"
            />
          </template>
          <template #cell(doctor_name_action_type_name)="data">
            <span class="d-block">{{ data.item.doctor_name }}</span>
            <span class="d-block">{{ data.item.action_type_name }}</span>
          </template>
          <template #cell(display_date)="data">
            {{
              new Date(data.item.date).toLocaleString("id-ID", {
                weekday: "long",
                day: "numeric",
                month: "numeric",
                year: "numeric",
              })
            }}
          </template>
          <template #cell(appointment_status_name)="data">
            <div class="text-center">
              {{ data.item.appointment_status_name }}
            </div>
          </template>
        </b-table>
      </b-modal>

      <!-- print layout -->
      <StickerPrint :data="print" />

      <b-modal id="modalShowPreview" size="xl" hide-footer title="Cetak Stiker">
        <b-form-group
          id="input-group-name"
          label="Nama:"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="print.patient_name"
            placeholder="Nama Lengkap"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group label="Jenis Kelamin:">
          <div class="custom-control custom-radio d-inline m-2">
            <input
              type="radio"
              id="male"
              name="gender"
              class="custom-control-input"
              value="male"
              v-model="print.patient_gender"
            />
            <label class="custom-control-label" for="male">Laki-laki</label>
          </div>
          <div class="custom-control custom-radio d-inline m-2">
            <input
              type="radio"
              id="female"
              name="gender"
              class="custom-control-input"
              value="female"
              v-model="print.patient_gender"
            />
            <label class="custom-control-label" for="female">Perempuan</label>
          </div>
        </b-form-group>
        <b-form-group
          id="input-group-birth-date"
          label="Tempat & Tanggal Lahir:"
          label-for="input-sip-birth-date"
        >
          <b-input-group>
            <v-dialog
              ref="dialog"
              v-model="modal2"
              :return-value.sync="print.patient_birth_date"
              persistent
              width="350px"
            >
              <template v-slot:activator="{ on, attrs }">
                <b-form-input
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  id="input-trigger-modal-patient"
                  v-model="print.patient_birth_date"
                  placeholder="Tanggal Lahir"
                >
                </b-form-input>
              </template>

              <v-date-picker
                v-if="modal2"
                v-model="print.patient_birth_date"
                locale="id"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal2 = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="countPatientAge">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </b-input-group>
        </b-form-group>
        <b-form-group id="input-group-age" label="Usia:" label-for="input-age">
          <b-form-input
            id="input-age"
            v-model="print.patient_age"
            placeholder="Usia"
            disabled
          >
          </b-form-input>
        </b-form-group>

        <div class="d-flex justify-content-end">
          <div class="">
            <b-button variant="primary" @click="btnPrintStickerOnClick">
              <i class="fas fa-print"></i> Cetak
            </b-button>
            <b-button
              class="ml-2"
              variant="secondary"
              @click="$bvModal.hide('modalShowPreview')"
            >
              Batal
            </b-button>
          </div>
        </div>
      </b-modal>
    </div>
  </v-app>
</template>

<script>
import { debounce } from "@/core/modules/Helper.js";
import XLSX from "xlsx";
import StickerPrint from "@/component/appointments/StickerPrint.vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import { getUser } from "@/core/services/jwt.service.js";
import moment from "moment";

export default {
  props: {
    isToday: Boolean,
    hasDoctor: Boolean,
    hasPatient: Boolean,
    isMyAppointment: Boolean,
    fields: Array,
    fieldPopUp: Array,
    items: Array,
    itemExcel: Array,
    itemPopUp: Array,
    perPage: Number,
    currentPage: Number,
    totalRows: Number,
    tabFiled: Number,
  },

  components: {
    Treeselect,
    StickerPrint,
    XLSX,
  },

  data() {
    return {
      modal2: false,
      // Filter
      filter: {
        appointment_status_id: "",
        doctor_id: "",
        patient_name: "",
        start_date: "",
        end_date: "",
      },
      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days: true,
        month: false,
        lastMonth: false,
        year: false,
        period: false,
      },
      // Appointment Statuses
      appointmentStatuses: [],
      // Options
      doctors: [],
      // Radio Options
      radioAppointmentStatus: [],
      // Others
      item: {},
      tabPosition: 2,
      tabActive: false,
      tabNumber: 5,
      // sticker print data
      print: {
        patient_number: "",
        patient_name: "",
        patient_gender: "",
        patient_age: "",
        patient_birth_date: "",
        appointment_date: "",
        patient_id: "",
      },
      // access management
      manipulateBtn: false,
      statusAppointment: false,
      caseManagerShortcut: false,
      paymentShortcut: false,
      prescriptionShortcut: false,
      medicalActionShortcut: false,
      // current user
      currentUser: getUser(),
    };
  },

  methods: {
    async addMedicalRecord(item) {
      SwalLoading.fire();
      if (this.currentUser.user_type_id == 1) {
        let patient = await module.get("patients/" + item.patient_id);
        console.log("itemmmm ======", item);
        if (patient == null) {
          SwalLoading.close();
          Swal.fire({
            title: "Gagal",
            text: "Data pasien gagal ditemukan",
            icon: "error",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        } else {
          console.log("patient =========", patient);
          SwalLoading.close();
          if (patient.doctor_in_charge.includes(this.currentUser.id)) {
            this.$router.push(
              `/patient/cases?doctor_id=${item.doctor_id}&patient_id=${item.patient_id}&patient_name=${item.patient_name}&appointment_id=${item.id}&from=today_appointment`
            );
          } else {
            // Jika pasien ini belum terdaftar sebagai pasien yang ditangani oleh dokter yang ingin menambahkan rekam medis pasien tersebut
            await Swal.fire({
              title: `Pasien atas nama ${patient.name}(${patient.patient_id}) belum terdaftar sebagai pasien Anda, apakah tetap ingin menambahkan rekam medis ?`,
              text: "Jika pilih Ya, maka secara otomatis sistem akan menambahkan pasien ini menjadi pasien Anda",
              icon: "question",
              showCancelButton: true,
              confirmButtonText: `Ya`,
              cancelButtonText: `Tidak`,
            }).then(async (result) => {
              console.log("result swwe", result);
              if (result.isConfirmed == true) {
                patient.doctor_in_charge.push(this.currentUser.id);

                let patientFormObj = {
                  doctor_in_charge: JSON.stringify(patient.doctor_in_charge),
                  _method: "put",
                };

                let updatePatient = await module.submit(
                  patientFormObj,
                  `patients/${patient.id}/mini-update`
                );
                console.log("updatePatient", updatePatient);
                if (updatePatient.state != "error") {
                  this.$router.push(
                    `/patient/cases?doctor_id=${item.doctor_id}&patient_id=${item.patient_id}&patient_name=${item.patient_name}&appointment_id=${item.id}&from=today_appointment`
                  );
                } else {
                  SwalLoading.close();
                  Swal.fire({
                    title: "Gagal",
                    text: "Pasien ini gagal didaftarkan sebagai pasien Anda, harap hubungi admin",
                    icon: "warning",
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 8000,
                    timerProgressBar: true,
                  });
                }
              }
            });
          }
        }
      } else {
        SwalLoading.close();
        this.$router.push(
          `/patient/cases?doctor_id=${item.doctor_id}&patient_id=${item.patient_id}&patient_name=${item.patient_name}&appointment_id=${item.id}&from=today_appointment`
        );
      }
    },
    countPatientAge() {
      console.log("countPatientAge", this.print.patient_birth_date);
      console.log("refrs", this.$refs);
      this.$refs.dialog.save(this.print.patient_birth_date);
      this.calculateAge(this.print.patient_birth_date);
    },
    calculateAge(birthDate) {
      console.log("mulai hitung", birthDate);
      const today = new Date();
      const birthDateObj = new Date(birthDate);
      let age = today.getFullYear() - birthDateObj.getFullYear();
      const monthDiff = today.getMonth() - birthDateObj.getMonth();
      const dayDiff = today.getDate() - birthDateObj.getDate();

      if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
        age--;
      }

      this.print.patient_age = age;
    },
    showDataPreview(item) {
      let genderTemp = item.patient_gender;
      let gender = "";

      if (genderTemp.toLowerCase() == "perempuan") gender = "female";
      else if (genderTemp.toLowerCase() == "laki-laki") gender = "male";

      this.print.patient_id = item.patient_id;
      this.print.patient_number = item.patient_number;
      this.print.patient_name = item.display_patient_name;
      this.print.patient_gender = item.patient_gender;
      this.print.patient_birth_date = item.patient_birth_date;
      this.print.patient_age = item.patient_age;
      console.log("item", item);
      console.log("print", this.print);
      this.$bvModal.show("modalShowPreview");
    },
    btnPrintStickerOnClick(item) {
      // this.print.patient_number = item.patient_number;
      // this.print.patient_name = item.display_patient_name;
      // this.print.patient_gender = item.patient_gender;
      // this.print.patient_birth_date = item.patient_birth_date;
      // this.print.patient_age = item.patient_age;
      // this.print.appointment_date = item.datde;
      // this.print.patient_id = item.patient_id;
      setTimeout(() => {
        this.$htmlToPaper("print", {
          name: "cetak stiker",
          specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
          styles: [
            `/sticker-new.css`,
            "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
            "https://unpkg.com/kidlat-css/css/kidlat.css",
          ],
        });
      }, 500);

      this.patientMiniUpdate();
    },
    async patientMiniUpdate() {
      let data = {
        id: this.print.patient_id,
        name: this.print.patient_name,
        gender: this.print.patient_gender,
        birt_date: this.print.patient_birth_date,
        _method: "put",
      };

      await module.submitWithoutAlert(data, `patients/${data.id}/mini-update`);
      this.$root.$emit("refreshAppointment");
    },
    btnExcelOnClick() {
      const data = XLSX.utils.json_to_sheet(this.itemExcel);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "Daftar Perjanjian Klnik Hayandra.xlsx");
    },

    async getAppointmentStatuses() {
      this.appointmentStatuses = await module.get("appointment-statuses");
      this.appointmentStatuses.forEach((value) => {
        this.radioAppointmentStatus.push({ text: value.name, value: value.id });
      });
    },

    async getDoctorsOption() {
      let response = await module.setDoctorTreeSelect("doctors");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.doctors = response.data;
        this.doctors.unshift({
          label: "Filter Berdasar Dokter",
          id: "",
          isDisabled: true,
        });
      }
    },

    pageOnClick(evt, page) {
      evt.preventDefault();
      this.$emit("pageOnClick", page);
    },

    resetFilterPeriode() {
      this.filter = validation.clearForm(this.filter);
      if (this.currentUser.user_type_id == 1) {
        this.filter.doctor_id = this.currentUser.id;
      }
      this.btnActiveOnClick(this.lastPeriodType);
      this.$emit("filter", this.filter);
    },

    filterByPeriode() {
      this.$emit("filter", this.filter);
    },

    filterByPatient() {
      debounce(() => {
        this.tabPosition = 0;
        this.filter.start_date = "";
        this.filter.end_date = "";
        this.$emit("filterByPatient", this.filter);
      }, 500);
    },

    resetAppointmentStatus() {
      this.filter.appointment_status_id = "";
      this.$emit("filter", this.filter);
    },

    async filterByDoctor(evt) {
      await this.filter.doctor_id;
      this.$emit("filter", this.filter);
    },

    navStatusOnClick(appointmentStatusId) {
      this.filter.appointment_status_id = appointmentStatusId;
      this.$emit("filter", this.filter);
    },

    btnStatusOnclick(item) {
      for (const [key, value] of Object.entries(item)) {
        this.item[key] = value;
      }
    },

    btnStatusColor(id) {
      if (id == 5) {
        return "bg-light-danger text-danger";
      } else if (id == 4) {
        return "bg-light-info text-info";
      } else if (id == 3) {
        return "bg-light-success text-success";
      } else if (id == 2) {
        return "bg-light-warning text-warning";
      } else if (id == 1) {
        return "bg-light-primary text-primary";
      } else {
        return "bg-light text-dark";
      }
    },

    async modalStatusOnSubmit(evt) {
      this.item["_method"] = "put";
      let response = await module.submit(
        this.item,
        `appointments/change-status/${this.item.id}`
      );
      // Check Response
      if (response.state == "error") {
        Swal.fire("Gagal", "Terjadi Kesalahan", "error");
      } else {
        // Success
        this.$root.$emit("UpdateNewAppointment");
        this.$emit("appointmentOnStatusChange");
        Swal.fire(response.success.title, response.success.message, "success");
      }
    },

    modalStatusOnHide() {
      this.item = {};
    },

    btnDeleteOnClick(id) {
      this.$emit("btnDeleteOnClick", id);
    },

    // Global emit
    globalEmit() {
      this.$root.$emit("filterAppointment", this.filter);
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "5002") this.manipulateBtn = true;
        if (access_right[a] == "5003") this.statusAppointment = true;
        if (access_right[a] == "3006") this.caseManagerShortcut = true;
        if (access_right[a] == "6002") this.paymentShortcut = true;
        if (access_right[a] == "8002") this.prescriptionShortcut = true;
        if (access_right[a] == "1202") this.medicalActionShortcut = true;
      }
    },

    btnPeriodeOnClick() {
      if (this.onShowPeriod == false) {
        this.onShowPeriod = true;
        this.btnActiveOnClick("period");
      } else {
        this.onShowPeriod = false;
        this.btnActiveOnClick("period");
      }
    },

    btnActiveOnClick(type) {
      if (type == "days") {
        // set time
        this.filter.start_date = moment()
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        // this.pagination()
        this.$emit("filter", this.filter);

        // set btn Active
        this.btnActive.days = true;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "month") {
        // set time
        this.filter.start_date = moment()
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        // this.pagination()
        this.$emit("filter", this.filter);

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = true;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "lastMonth") {
        // set time
        this.filter.start_date = moment()
          .subtract(1, "months")
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .subtract(1, "months")
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        // this.pagination()
        this.$emit("filter", this.filter);

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = true;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "year") {
        // set time
        this.filter.start_date = moment()
          .startOf("year")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("year")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        // this.pagination()
        this.$emit("filter", this.filter);

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = true;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "period") {
        // set time
        this.filter.start_date = "";
        this.filter.end_date = "";

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = true;
      }
    },
  },

  watch: {
    "filter.doctor_id": function (newVal, oldVal) {
      if (typeof newVal === "undefined") {
        this.filter.doctor_id = "";
        this.filterByDoctor();
      }
    },
  },

  mounted() {
    this.setActiveMenu();
    // Get Appointment Statuses
    this.getAppointmentStatuses();
    if (this.currentUser.user_type_id != 1) {
      // Get Options
      this.getDoctorsOption();
    }
    this.$root.$on("appointment-submission", () => {
      this.tabActive = true;
      this.filter.appointment_status_id = 5;
      this.globalEmit();
    });
  },
};
</script>

<style scoped>
.appointment-status-tabs .nav-tabs {
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
}

.appointment-status-tabs .nav-tabs .nav-link {
  white-space: nowrap;
}
</style>
