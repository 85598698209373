<template>
  <div  style="display: none">
    <div id="printSticker">
      <!-- print preview -->
      <div id="printPreview">
        <div class="row justify-content-center align-items-center h-100 w-100">
          <div
            class="col-lg-5 col-12 sticker-container"
            v-for="loop in dataprint.display_medicine"
            :key="loop"
          >
            <div class="card border-secondary" style="border-radius: 0px">
              <div class="card-body mx-2">
                <div class="d-flex align-items-center justify-content-end">
                  <span>{{ formatDate1(dataprint.date) }}</span>
                </div>
                <div>
                  <div>
                    <span
                      >{{ dataprint.patient_title }}.
                      {{ dataprint.patient_name }}</span
                    >
                    <br />
                    <span
                      >{{ dataprint.patient_age }}thn/
                      {{ dataprint.patient_birt_date_display }}</span
                    >
                  </div>
                  <table
                    class="table-borderless"
                    cellspacing="3"
                    style="border-collapse: initial !important"
                  >
                    <tr>
                      <td>Nama Obat</td>
                      <td>:</td>
                      <td>{{ loop.name }}</td>
                    </tr>
                    <tr>
                      <td>Fungsi</td>
                      <td>:</td>
                      <td>{{ loop.utility }}</td>
                    </tr>
                    <tr>
                      <td>Aturan Pakai</td>
                      <td>:</td>
                      <td>{{ loop.rule }}</td>
                    </tr>
                    <tr>
                      <td>Waktu</td>
                      <td>:</td>
                      <td>{{ loop.instruction }}</td>
                    </tr>
                    <tr>
                      <td>Expired Date</td>
                      <td>:</td>
                      <td>{{ formatDate(loop.expired_date) }}</td>
                    </tr>
                    <tr>
                      <td>Catatan</td>
                      <td>:</td>
                      <td>{{ loop.notes }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- print layout -->
      <div id="printLayout" style="display: none">
        <div class="" v-for="(loop, i) in dataprint.display_medicine" :key="i">
          <div
            :class="
              i % 2 == 0 ? 'sticker-container-right' : 'sticker-container-left'
            "
            style="border-radius: 0px"
          >
            <div
              class=""
              :class="
                i % 2 == 0
                  ? 'inner-sticker-container-right'
                  : 'inner-sticker-container-left'
              "
            >
              <div>
                <div class="" style="position: absolute; right: 20mm">
                  <span><strong>{{ formatDate1(dataprint.date) }}</strong></span>
                </div>
                <div>
                  <div>
                    <span
                      ><strong>{{ dataprint.patient_title }}.
                      {{ dataprint.patient_name }}</strong></span
                    >
                    <br />
                    <span
                      ><strong>{{ dataprint.patient_age }}thn /
                      {{ dataprint.patient_birt_date_display }}</strong></span
                    >
                  </div>
                  <table
                    class="table-borderless"
                    cellspacing="3"
                    style="border-collapse: initial !important; margin-top: 1mm;"
                  >
                    <tr>
                      <td><strong>Nama Obat</strong></td>
                      <td><strong>:</strong></td>
                      <td><strong>{{ loop.name }}</strong></td>
                    </tr>
                    <tr>
                      <td><strong>Fungsi</strong></td>
                      <td><strong>:</strong></td>
                      <td style="width: 300px"><strong>{{ loop.utility }}</strong></td>
                    </tr>
                    <tr>
                      <td><strong>Aturan Pakai</strong></td>
                      <td><strong>:</strong></td>
                      <td><strong>{{ loop.rule }}</strong></td>
                    </tr>
                    <tr>
                      <td><strong>Waktu</strong></td>
                      <td><strong>:</strong></td>
                      <td><strong>{{ loop.instruction }}</strong></td>
                    </tr>
                    <tr>
                      <td><strong>Expired Date</strong></td>
                      <td><strong>:</strong></td>
                      <td><strong>{{ formatDate(loop.expired_date) }}</strong></td>
                    </tr>
                    <tr>
                      <td><strong>Catatan</strong></td>
                      <td><strong>:</strong></td>
                      <td><strong>{{ loop.notes }}</strong></td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="sticker-container-right" style="border-radius: 0px">
            <div
              class="d-flex inner-sticker-container-right justify-content-center"
            >
              <div>
                <div class="patient-name">
                  <strong></strong>
                </div>
                <div class="patient-info">
                  <strong>----------JJJJJ asdasd yuk lah</strong>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataprint: Object,
  },

  data() {
    return {
      count: 2,
      medicines: [],
      data: {
        patient_name: "asdasd",
      },
    };
  },

  methods: {
    formatDate1(date) {
      let dateString = date;
      let dateParts = dateString.split("-");

      let formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
      return formattedDate;
    },
    formatDate(dateString) {
      const date = new Date(dateString);

      const monthNames = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];

      const formattedDate = `${
        monthNames[date.getMonth()]
      } ${date.getFullYear()}`;

      return formattedDate;
    },
  },
  mounted() {
    console.log("mounted dataprint", this.dataprint);
    //   this.medicines = JSON.parse(this.dataprint.medicine);
  },
  watch: {
    dataprint: function (newVal, oldVal) {
      this.medicines = JSON.parse(this.dataprint.medicine);
      console.log("wactch dataprint", this.dataprint);
    },
  },
};
</script>

<style>
</style>