import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCalendar } from 'vuetify/lib/components/VCalendar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,[_c('div',{staticClass:"row justify-content-end align-items-center mb-2"},[_c('div',{staticClass:"col-md-3 co-12"},[_c('treeselect',{attrs:{"id":"input-doctor","multiple":false,"options":_vm.doctors,"placeholder":"Filter Berdasar Dokter"},on:{"select":_vm.filterDoctorOnSelect},model:{value:(_vm.filter.doctor_id),callback:function ($$v) {_vm.$set(_vm.filter, "doctor_id", $$v)},expression:"filter.doctor_id"}})],1)]),_c(VSheet,{attrs:{"height":"600"}},[_c(VCalendar,{ref:"calendar",attrs:{"now":_vm.today,"value":_vm.today,"events":_vm.events,"event-color":_vm.getEventColor,"color":"primary","type":"week","locale":"id"},on:{"click:event":_vm.showEvent,"change":_vm.getEvents}}),_c(VMenu,{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-x":""},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c(VCard,{attrs:{"color":"grey lighten-4","min-width":"350px","flat":""}},[_c(VToolbar,{attrs:{"color":_vm.selectedEvent.color,"dark":""}},[_c(VToolbarTitle,{domProps:{"innerHTML":_vm._s(("Praktek " + (_vm.selectedEvent.name)))}}),_c(VSpacer)],1),_c(VCardText,[_c('span',{domProps:{"innerHTML":_vm._s(("<p style='font-size: 13px'>" + (_vm.selectedEvent.details) + "</p>"))}})]),_c(VCardActions,[_c(VBtn,{attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.selectedOpen = false}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }